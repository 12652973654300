import React from "react";
import styled from "styled-components";

function Section() {
    return (
        <Wrap>
            <MainContent>
                <img src="/images/white-logo.jpg" height="150" class="logo" alt="logo"/>
                <div>
                    <h1>Thank you!</h1>
                    <h2>2016 - 2020</h2>
                </div>
            </MainContent>

            <FooterBox>
                <p1>Made with ❤ in <strong>Oregon</strong></p1>
            </FooterBox>
        </Wrap>
    )
}

export default Section

const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/images/acornbus-water-salute.jpg');
`

const MainContent = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    
    padding: 1%;

    h1, h2 {
        text-align: left;
        margin: 1vh;
    }


`




const FooterBox = styled.div`
    position: absolute;
    bottom: 2%;
    right: 2vw;
    display: flex;






`

